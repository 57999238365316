//
// Themes
// --------------------------------------------------

// Default
.text-default {
    .text-emphasis-variant(@brand-primary);

    &-dark {
        .text-emphasis-variant(@brand-dark);
    }

    &-darker {
        .text-emphasis-variant(@brand-darker);
    }

    &-light {
        .text-emphasis-variant(@brand-light);
    }

    &-lighter {
        .text-emphasis-variant(@brand-lighter);
    }
}

.bg-default {
    .bg-variant(@brand-primary);

    &-op {
        .bg-variant(fade(@brand-primary, 75%));
    }

    &-dark {
        .bg-variant(@brand-dark);
    }

    &-dark-op {
        .bg-variant(fade(@brand-dark, 83%));
    }

    &-darker {
        .bg-variant(@brand-darker);
    }

    &-light {
        .bg-variant(@brand-light);
    }

    &-lighter {
        .bg-variant(@brand-lighter);
    }
}

// Amethyst
.text-amethyst {
    .text-emphasis-variant(@theme-amethyst-base);

    &-dark {
        .text-emphasis-variant(@theme-amethyst-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-amethyst-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-amethyst-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-amethyst-lighter);
    }
}

.bg-amethyst {
    .bg-variant(@theme-amethyst-base);

    &-op {
        .bg-variant(fade(@theme-amethyst-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-amethyst-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-amethyst-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-amethyst-darker);
    }

    &-light {
        .bg-variant(@theme-amethyst-light);
    }

    &-lighter {
        .bg-variant(@theme-amethyst-lighter);
    }
}

// City
.text-city {
    .text-emphasis-variant(@theme-city-base);

    &-dark {
        .text-emphasis-variant(@theme-city-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-city-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-city-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-city-lighter);
    }
}

.bg-city {
    .bg-variant(@theme-city-base);

    &-op {
        .bg-variant(fade(@theme-city-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-city-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-city-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-city-darker);
    }

    &-light {
        .bg-variant(@theme-city-light);
    }

    &-lighter {
        .bg-variant(@theme-city-lighter);
    }
}

// Flat
.text-flat {
    .text-emphasis-variant(@theme-flat-base);

    &-dark {
        .text-emphasis-variant(@theme-flat-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-flat-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-flat-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-flat-lighter);
    }
}

.bg-flat {
    .bg-variant(@theme-flat-base);

    &-op {
        .bg-variant(fade(@theme-flat-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-flat-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-flat-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-flat-darker);
    }

    &-light {
        .bg-variant(@theme-flat-light);
    }

    &-lighter {
        .bg-variant(@theme-flat-lighter);
    }
}

// Modern
.text-modern {
    .text-emphasis-variant(@theme-modern-base);

    &-dark {
        .text-emphasis-variant(@theme-modern-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-modern-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-modern-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-modern-lighter);
    }
}

.bg-modern {
    .bg-variant(@theme-modern-base);

    &-op {
        .bg-variant(fade(@theme-modern-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-modern-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-modern-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-modern-darker);
    }

    &-light {
        .bg-variant(@theme-modern-light);
    }

    &-lighter {
        .bg-variant(@theme-modern-lighter);
    }
}

// Smooth
.text-smooth {
    .text-emphasis-variant(@theme-smooth-base);

    &-dark {
        .text-emphasis-variant(@theme-smooth-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-smooth-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-smooth-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-smooth-lighter);
    }
}

.bg-smooth {
    .bg-variant(@theme-smooth-base);

    &-op {
        .bg-variant(fade(@theme-smooth-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-smooth-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-smooth-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-smooth-darker);
    }

    &-light {
        .bg-variant(@theme-smooth-light);
    }

    &-lighter {
        .bg-variant(@theme-smooth-lighter);
    }
}