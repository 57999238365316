//
// Simple Line Icons
// --------------------------------------------------

@font-face {
    font-family: Simple-Line-Icons;
    src: url(../fonts/Simple-Line-Icons.eot);
    src: url(../fonts/Simple-Line-Icons.eot?#iefix) format('embedded-opentype'),url(../fonts/Simple-Line-Icons.woff) format('woff'),url(../fonts/Simple-Line-Icons.ttf) format('truetype'),url(../fonts/Simple-Line-Icons.svg#Simple-Line-Icons) format('svg');
    font-weight: 400;
    font-style: normal;
}

.si {
    font-family: Simple-Line-Icons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.btn .si {
    position: relative;
    bottom: -2px;
    display: inline-block;
}

.si-user-female:before {
    content: "\e000";
}

.si-user-follow:before {
    content: "\e002";
}

.si-user-following:before {
    content: "\e003";
}

.si-user-unfollow:before {
    content: "\e004";
}

.si-trophy:before {
    content: "\e006";
}

.si-screen-smartphone:before {
    content: "\e010";
}

.si-screen-desktop:before {
    content: "\e011";
}

.si-plane:before {
    content: "\e012";
}

.si-notebook:before {
    content: "\e013";
}

.si-moustache:before {
    content: "\e014";
}

.si-mouse:before {
    content: "\e015";
}

.si-magnet:before {
    content: "\e016";
}

.si-energy:before {
    content: "\e020";
}

.si-emoticon-smile:before {
    content: "\e021";
}

.si-disc:before {
    content: "\e022";
}

.si-cursor-move:before {
    content: "\e023";
}

.si-crop:before {
    content: "\e024";
}

.si-credit-card:before {
    content: "\e025";
}

.si-chemistry:before {
    content: "\e026";
}

.si-user:before {
    content: "\e005";
}

.si-speedometer:before {
    content: "\e007";
}

.si-social-youtube:before {
    content: "\e008";
}

.si-social-twitter:before {
    content: "\e009";
}

.si-social-tumblr:before {
    content: "\e00a";
}

.si-social-facebook:before {
    content: "\e00b";
}

.si-social-dropbox:before {
    content: "\e00c";
}

.si-social-dribbble:before {
    content: "\e00d";
}

.si-shield:before {
    content: "\e00e";
}

.si-screen-tablet:before {
    content: "\e00f";
}

.si-magic-wand:before {
    content: "\e017";
}

.si-hourglass:before {
    content: "\e018";
}

.si-graduation:before {
    content: "\e019";
}

.si-ghost:before {
    content: "\e01a";
}

.si-game-controller:before {
    content: "\e01b";
}

.si-fire:before {
    content: "\e01c";
}

.si-eyeglasses:before {
    content: "\e01d";
}

.si-envelope-open:before {
    content: "\e01e";
}

.si-envelope-letter:before {
    content: "\e01f";
}

.si-bell:before {
    content: "\e027";
}

.si-badge:before {
    content: "\e028";
}

.si-anchor:before {
    content: "\e029";
}

.si-wallet:before {
    content: "\e02a";
}

.si-vector:before {
    content: "\e02b";
}

.si-speech:before {
    content: "\e02c";
}

.si-puzzle:before {
    content: "\e02d";
}

.si-printer:before {
    content: "\e02e";
}

.si-present:before {
    content: "\e02f";
}

.si-playlist:before {
    content: "\e030";
}

.si-pin:before {
    content: "\e031";
}

.si-picture:before {
    content: "\e032";
}

.si-map:before {
    content: "\e033";
}

.si-layers:before {
    content: "\e034";
}

.si-handbag:before {
    content: "\e035";
}

.si-globe-alt:before {
    content: "\e036";
}

.si-globe:before {
    content: "\e037";
}

.si-frame:before {
    content: "\e038";
}

.si-folder-alt:before {
    content: "\e039";
}

.si-film:before {
    content: "\e03a";
}

.si-feed:before {
    content: "\e03b";
}

.si-earphones-alt:before {
    content: "\e03c";
}

.si-earphones:before {
    content: "\e03d";
}

.si-drop:before {
    content: "\e03e";
}

.si-drawer:before {
    content: "\e03f";
}

.si-docs:before {
    content: "\e040";
}

.si-directions:before {
    content: "\e041";
}

.si-direction:before {
    content: "\e042";
}

.si-diamond:before {
    content: "\e043";
}

.si-cup:before {
    content: "\e044";
}

.si-compass:before {
    content: "\e045";
}

.si-call-out:before {
    content: "\e046";
}

.si-call-in:before {
    content: "\e047";
}

.si-call-end:before {
    content: "\e048";
}

.si-calculator:before {
    content: "\e049";
}

.si-bubbles:before {
    content: "\e04a";
}

.si-briefcase:before {
    content: "\e04b";
}

.si-book-open:before {
    content: "\e04c";
}

.si-basket-loaded:before {
    content: "\e04d";
}

.si-basket:before {
    content: "\e04e";
}

.si-bag:before {
    content: "\e04f";
}

.si-action-undo:before {
    content: "\e050";
}

.si-action-redo:before {
    content: "\e051";
}

.si-wrench:before {
    content: "\e052";
}

.si-umbrella:before {
    content: "\e053";
}

.si-trash:before {
    content: "\e054";
}

.si-tag:before {
    content: "\e055";
}

.si-support:before {
    content: "\e056";
}

.si-size-fullscreen:before {
    content: "\e057";
}

.si-size-actual:before {
    content: "\e058";
}

.si-shuffle:before {
    content: "\e059";
}

.si-share-alt:before {
    content: "\e05a";
}

.si-share:before {
    content: "\e05b";
}

.si-rocket:before {
    content: "\e05c";
}

.si-question:before {
    content: "\e05d";
}

.si-pie-chart:before {
    content: "\e05e";
}

.si-pencil:before {
    content: "\e05f";
}

.si-note:before {
    content: "\e060";
}

.si-music-tone-alt:before {
    content: "\e061";
}

.si-music-tone:before {
    content: "\e062";
}

.si-microphone:before {
    content: "\e063";
}

.si-loop:before {
    content: "\e064";
}

.si-logout:before {
    content: "\e065";
}

.si-login:before {
    content: "\e066";
}

.si-list:before {
    content: "\e067";
}

.si-like:before {
    content: "\e068";
}

.si-home:before {
    content: "\e069";
}

.si-grid:before {
    content: "\e06a";
}

.si-graph:before {
    content: "\e06b";
}

.si-equalizer:before {
    content: "\e06c";
}

.si-dislike:before {
    content: "\e06d";
}

.si-cursor:before {
    content: "\e06e";
}

.si-control-start:before {
    content: "\e06f";
}

.si-control-rewind:before {
    content: "\e070";
}

.si-control-play:before {
    content: "\e071";
}

.si-control-pause:before {
    content: "\e072";
}

.si-control-forward:before {
    content: "\e073";
}

.si-control-end:before {
    content: "\e074";
}

.si-calendar:before {
    content: "\e075";
}

.si-bulb:before {
    content: "\e076";
}

.si-bar-chart:before {
    content: "\e077";
}

.si-arrow-up:before {
    content: "\e078";
}

.si-arrow-right:before {
    content: "\e079";
}

.si-arrow-left:before {
    content: "\e07a";
}

.si-arrow-down:before {
    content: "\e07b";
}

.si-ban:before {
    content: "\e07c";
}

.si-bubble:before {
    content: "\e07d";
}

.si-camcorder:before {
    content: "\e07e";
}

.si-camera:before {
    content: "\e07f";
}

.si-check:before {
    content: "\e080";
}

.si-clock:before {
    content: "\e081";
}

.si-close:before {
    content: "\e082";
}

.si-cloud-download:before {
    content: "\e083";
}

.si-cloud-upload:before {
    content: "\e084";
}

.si-doc:before {
    content: "\e085";
}

.si-envelope:before {
    content: "\e086";
}

.si-eye:before {
    content: "\e087";
}

.si-flag:before {
    content: "\e088";
}

.si-folder:before {
    content: "\e089";
}

.si-heart:before {
    content: "\e08a";
}

.si-info:before {
    content: "\e08b";
}

.si-key:before {
    content: "\e08c";
}

.si-link:before {
    content: "\e08d";
}

.si-lock:before {
    content: "\e08e";
}

.si-lock-open:before {
    content: "\e08f";
}

.si-magnifier:before {
    content: "\e090";
}

.si-magnifier-add:before {
    content: "\e091";
}

.si-magnifier-remove:before {
    content: "\e092";
}

.si-paper-clip:before {
    content: "\e093";
}

.si-paper-plane:before {
    content: "\e094";
}

.si-plus:before {
    content: "\e095";
}

.si-pointer:before {
    content: "\e096";
}

.si-power:before {
    content: "\e097";
}

.si-refresh:before {
    content: "\e098";
}

.si-reload:before {
    content: "\e099";
}

.si-settings:before {
    content: "\e09a";
}

.si-star:before {
    content: "\e09b";
}

.si-symbol-female:before {
    content: "\e09c";
}

.si-symbol-male:before {
    content: "\e09d";
}

.si-target:before {
    content: "\e09e";
}

.si-volume-1:before {
    content: "\e09f";
}

.si-volume-2:before {
    content: "\e0a0";
}

.si-volume-off:before {
    content: "\e0a1";
}

.si-users:before {
    content: "\e001";
}